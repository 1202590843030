<template>
  <v-card elevation="0" class="pa-5">
    <div class="mb-8">
      <v-img :src="require('@/assets/logo.svg')" width="200px" class="mx-auto mb-5" cover></v-img>
      <h4>Bienvenido al sistema de solicitudes</h4>
      <small>Por favor inicia sesión para empezar</small>
    </div>    
    <v-text-field
    type="email"
    label="Email"
    density="compact"
    variant="outlined"
    hide-details="auto"
    class="mb-3"
    v-model="user.email"
    ></v-text-field>
    <v-text-field
    type="password"
    label="Contraseña"
    density="compact"
    variant="outlined"
    hide-details="auto"
    class="mb-3"
    v-model="user.password"
    @keyup.enter="login()"
    ></v-text-field>
    
    <v-btn variant="flat" size="small" class="mb-2" @click="switchComponent('FormResetPassComponent')"> Olvidé Contraseña</v-btn>
    <v-btn elevation="0" @click="login()" color="indigo 900" block class="font-weight-medium"> Iniciar sesión </v-btn>
    <v-alert v-if="errorLogin.type" :type="errorLogin.type" variant="tonal"  closable density="compact" class="my-2 pa-1">
      <small>{{ errorLogin.message }}</small>
    </v-alert>

    <v-img :src="require('@/assets/logo-tic.svg')" width="70px" class="mx-auto my-5" cover></v-img>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
export default {
    computed:
    {
      ...mapState(['user']),
      ...mapState('login', ['errorLogin'])
    },
    methods:{
        ...mapMutations('login',['switchComponent']),
        ...mapActions('login', ['login'])
    }    
}
</script>