import Module from './Module.vue';
import Login from './views/LoginView';
import RestorePasswordView from './views/RestorePasswordView.vue'

const moduleRoute = {
    path: "/login",
    component: Module,
    children: [
      {
        path: "/login",
        component: Login,
        name: "Login",
        meta: {
          requiresGuest: true,
          title: "Login",
        },
      },
      {
        path: "/restore-password/:email",
        component: RestorePasswordView,
        name: "RestorePass",
        meta: {
          requiresGuest: true,
          title: "Restaurar contraseña",
        },
      }
    ]
  };
  
  export default router => {
    router.addRoute(moduleRoute);
  };