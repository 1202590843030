import Swal from "sweetalert2"

export default{
    namespaced: true,
    state:{
        //api: "http://localhost:8000/api/",
        //api: "http://192.168.100.242:8000/api/", 
        api: "https://helpdesk-api.cetemin.edu.pe/api/", 
        optionAxios:{
            url: "",
            method: "",
            headers:{
                'content-type': "application/json",
                Accept: "application/json",
                Authorization: ""
            },
            params: null,
            data: null
        },
        apiTynyMCE:'uc2k62fkrziti5amh463adplgaxghs9aw9p1tx19zm44c7wx'
    },


    mutations:{

        alertLoading(){
            Swal.fire({
                text: "Cargando la solicitud",
                icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                width: "300px"
            })
        },

        alertConfirm(state, textconfirm){
            Swal.fire({
                text: textconfirm,
                icon: "success",
                width: "300px",
                timer: 2000
            })
        },

        alertError(state){
            Swal.fire({
                text: "Ha ocurrido un error",
                icon: "error",
                width: "300px",
            })
        },


        alertWarning(state, textwarnin){
            Swal.fire({
                text: textwarnin,
                icon: "warning",
                width: "300px",
                timer: 2000
            })
        },

    

    }
}