<template>
  <v-layout>
    <SilderMultipurpose v-if="getterLogginIn"/>
    <navigation-component  v-if="getterLogginIn"/>
    <v-main>
      <navbar-component  v-if="getterLogginIn"/>
        <router-view />
      <v-footer app class="d-none d-md-block" height="40" id="footercentral">
        <v-col cols="12" class="mx-auto d-flex justify-space-between py-0 ">
          <span class="text-caption font-weight-bold">HelpDesk - 2024</span>
          <span class="text-caption font-weight-bold">TIC-CETEMIN</span>
        </v-col>
      </v-footer>
      <MenuBottomComponent v-if="$vuetify.display.xs && getterLogginIn" />
    </v-main>
    <v-overlay v-model="overlayLogin" class="bgBlackTheme d-flex justify-center align-center">
      <span class="loader"></span>
    </v-overlay>
  </v-layout>
</template>

<style lang="scss">
$navigation-drawer-scrim-opacity: 0.8 !important;
</style>

<script>

import NavbarComponent from "@/components/layouts/NavbarComponent.vue";
import NavigationComponent from "@/components/layouts/NavigationComponent.vue";
import MenuBottomComponent from "@/components/layouts/MenuBottomComponent.vue";
import SilderMultipurpose from "./components/layouts/SilderMultipurpose.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import store from "./store";


export default {

  components: {
    NavbarComponent,
    NavigationComponent,
    MenuBottomComponent,
    SilderMultipurpose
  },
  computed:{
    ...mapGetters(["getterLogginIn"]),
    ...mapState(['overlayLogin'])
  },
  methods:{
    ...mapActions("configuracion/tipo", ["getAxiosTipo"]),
    ...mapActions("configuracion/categoria", ["getCategoriasAxios"]),
    ...mapActions("configuracion/subcategoria", ["getSubCategoriasAxios"]),
    ...mapActions("configuracion/prioridad", ["getPrioridadesAxios"]),
    ...mapActions("configuracion/estado", ["getEstadosAxios"]),
    ...mapActions('configuracion/sede', ['getSedesAxios']),
    ...mapActions('configuracion/agente', ['getAgentes']),
    ...mapActions('configuracion/notificacion', ['getConfigNotify']),
    ...mapActions('usuarios/roles', ['getAllRoles']),
    ...mapMutations(['setUser'])
  },
  created() {

    /**CARGA DE TODOS LOS RECURSOS NECESARIOS EN EL SISTEMA */
    this.getAxiosTipo();
    this.getPrioridadesAxios();
    this.getEstadosAxios();
    this.getCategoriasAxios();
    this.getSubCategoriasAxios();
    this.getSedesAxios();
    this.getAgentes();

    if(store.getters.getterLogginIn)
    {
      this.getConfigNotify();
      this.getAllRoles();

      //Necesario para cargar los roles
      store.commit('login/setUser', store.state.user)
      
    }
  },


};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  background-color: #ECEFF1 !important;
}

#app {
  [class*='text-'] {
    -webkit-font-smoothing: antialiased;
    font-family: 'Fira Sans', sans-serif !important;
    text-rendering: optimizeLegibility;
    ;
  }

  -webkit-font-smoothing: antialiased;
  font-family: 'Fira Sans', sans-serif !important;
  text-rendering: optimizeLegibility;

}
.bg-pattern{
  background-image: url('assets/bg-pattherns.jpg');
  background-repeat: repeat;
  background-size:contain;
}
.colorBlackTheme {
  color: #272829 !important;
}

.colorBlue1Theme {
  color: #19284e !important;
}

.colorBlue2Theme {
  color: #48589e !important;
}

.colorBlue3Theme {
  color: #858efa !important;
}

.colorLightTheme {
  color: #d2c9ff !important;
}

.bgBlackTheme {
  background-color: #12344D !important;
}

.bgBlue1Theme {
  background-color: #0E2A3E !important;
}

.bgBlue2Theme {
  background-color: #48589e !important;
}

.bgBlue3Theme {
  background-color: #858efa !important;
}

.bgLightTheme {
  background-color: #0E2A3E !important;
}

.bgLight2Theme {
  background-color: #F5F7F9 !important;
}

.bg-app {
  background-color: #EBEFF3 !important;
}

.bg-space{
  background-color: #FFFFFF !important;
}

.btn-system{
    height: 36px !important;
    border-radius: 4px !important;
    border: 1px solid #cfd7df !important;
    color: #12344d !important;
    cursor: pointer;
    padding: 2px 10px 3px 14px !important;
    margin-right: 2px;
    font-size: 14px !important;
    display: flex;
    align-content: center;
    -webkit-font-smoothing: antialiased;
    transition: .1s ease-in;
    background-image: linear-gradient(to bottom,#fff,#f3f5f7) !important;
    font-weight: 600 !important;

}

.btn-system >>> .v-btn--disabled.v-btn--variant-elevated{
  opacity: 0.5 !important;
}

.compact-full-input input.v-field__input {
  min-height: 36px !important;
  height: 36px !important;
  padding: 6px 10px !important;
  font-size: 12px;
  font-weight: 600;
}
.compact-full-input .v-field .v-field__input,
.compact-full-input  .v-field.v-field--variant-underlined .v-field__append-inner {
  padding: 10px 10px !important;
  min-height: 36px !important;
  height: 36px !important;
  font-size: 12px;
  font-weight: 600;
}


.solo-no-elevation .v-field--variant-solo, .v-field--variant-solo-filled {
  box-shadow: none !important;
}

.btn-float {
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 99;
}

.loader {
  width: 20px;
  height: 12px;
  display: block;
  margin: auto;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 20px;
  height: 12px;
  background: currentColor;
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 110%;
  animation: animloader  0.6s ease infinite alternate;
}
.loader::after {
  left: 110%;
  right: auto;
  animation-delay: 0.6s;
}

.v-btn--disabled.v-btn--variant-elevated, .v-btn--disabled.v-btn--variant-flat
{
  opacity: 0.2 !important;
}

.stamp {
  position: relative;
}

.stamp:before {
  position: absolute;
  top: -8px;
  display: block;
  content: "";
  background: radial-gradient(circle, transparent 8px, #fff 9px);
  background-size: 26px 40px;
  background-position: -20px -23px;
  width: 100%;
  height: 20px;
  z-index: 1;
}

.caja-texto-styles  ul, .caja-texto-styles  ol {
  margin-left: 30px !important;
}

.caja-texto-styles img {
  max-width: 100% !important;
}

.caja-texto-styles  table{
  border-collapse: collapse; /* Asegura que los bordes de las celdas se junten */
    width: 100%;
}

.caja-texto-styles table th, .caja-texto-styles table td{
  border: 2px solid #2f302f !important; /* Color y grosor del borde */
    padding: 8px; /* Espaciado interno de las celdas */
    text-align: left;
}



@keyframes animloader {
  0% {
    width: 20px;
  }
  100% {
    width: 48px;
  }
}


</style>


