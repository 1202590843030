<template>
  <v-card rounded="0" elevation="3" class="bgLight2Theme" outlined>
    <v-container fluid class="d-flex align-center">
      <v-btn v-if="ticket" :disabled="isDisabled(ticket.estado)" @click="switchDialogTicket()" class="btn-system mr-2"
        size="small" elevation="0">
        <v-icon start size="x-small">fa:fa-solid fa-reply</v-icon>
        <small>Responder</small></v-btn>

      <v-btn v-if="cierreBTN && !isRoleVisitor()" @click="closedTicket(ticket.idticket)" class="btn-system mr-2"
        size="small" elevation="0">
        <v-icon start size="small">mdi:mdi-check-circle-outline</v-icon>
        <small>Cerrar</small></v-btn>

      <v-btn v-if="ticket" @click="deleteTicket(ticket.idticket)" class="btn-system mr-2" size="small" elevation="0">
        <v-icon start size="x-small">fa:fa-solid fa-trash-can</v-icon>
        <small>Eliminar</small></v-btn>

    </v-container>
  </v-card>

  <v-container fluid class="pa-2">
    <!---SCKELETON LOADERS-->
    <v-row class="pa-0" v-if="ticket == null">
      <v-col cols="12" md="6" lg="8" class="py-1 py-md-3 pt-3"><v-skeleton-loader
          type="heading, subtitle, text, paragraph, actions, ossein"></v-skeleton-loader></v-col>
      <v-col cols="12" md="3" lg="2" class="px-md-0 py-1 py-md-3"><v-skeleton-loader
          type="subtitle, text, paragraph, actions, ossein"></v-skeleton-loader></v-col>
      <v-col cols="12" md="3" lg="2" class="px-md-2 py-1 py-md-3"><v-skeleton-loader
          type="article, actions"></v-skeleton-loader></v-col>
    </v-row>
    <!---SCKELETON LOADERS-->


    <v-row class="pa-0" v-if="ticket" no-gutters>

      <v-col cols="12" md="6" lg="8" xl="8" class="py-1 py-md-0 pt-3" style="width: 60%;">
        <div v-for="(hilo, i) in ticket.tickets_anidados">

          <div class="stamp mt-4">
            <v-card elevation="0" class="stamp rounded-b-0 mb-2 ">
              <v-card-actions>
                <v-chip label size="small" class="mr-1" color="teal">{{ hilo.categoria.nombre
                  }}</v-chip>
                <v-chip label size="small" color="teal">{{ hilo.prioridad.nombre }}</v-chip>
              </v-card-actions>
              <v-list-item class="pt-0 pb-0">
                <v-list-item-title style="white-space: normal !important" class="font-weight-medium"
                  :class="i == 0 ? 'text-h6' : 'text-subtitle-1'">
                  {{ i == 0 ? hilo.asunto : 'Ticket referido #' + i }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption text-md-body-2 py-2 text-black">
                  {{ fechasmall("DD [de] MMMM [de] YYYY", hilo.created_at) }} a las {{
                    fechasmall("hh:mm a",
                      hilo.created_at) }}
                </v-list-item-subtitle>
                <v-divider></v-divider>
              </v-list-item>
              <v-expansion-panels dense elevation="0">
                <v-expansion-panel>
                  <v-expansion-panel-title
                    class="d-flex justify-space-between align-center text-md-body-2 py-2 text-black text-blue-grey-darken-3">
                    <div v-if="i == 0" class="text-subtitle-2 text-center text-blue-grey-lighten-1">
                      TICKET INICIAL
                    </div>
                    <v-spacer></v-spacer>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-card elevation="0">
                      <v-card-text v-html="hilo.body" class="caja-texto-styles"> </v-card-text>
                    </v-card>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>


        </div>

        <div v-if="ticket.tickets_anidados && ticket.tickets_anidados.length > 0"
          class="pa-3 text-subtitle-2 text-center text-blue-grey-lighten-1">
          Último ticket # {{ ticket.tickets_anidados.length }}
        </div>



        <div class="stamp mt-3">
          <v-card elevation="0" class="rounded-b-0">
            <v-list-item class="pt-3 pt-md-5 pb-0">
              <CambiarCategoriaTicketComponent v-if="ticket.tickets_anidados.length == 0">
              </CambiarCategoriaTicketComponent>
              <v-list-item-title style="white-space: normal !important" class="font-weight-medium text-h6 mt-3"
                :class="ticket.tickets_anidados.length == 0 ? 'text-h6' : 'text-subtitle-1'">
                {{ ticket.tickets_anidados.length == 0 ? ticket.asunto : 'Último ticket referido #' +
                  ticket.tickets_anidados.length }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption text-md-body-2 py-2 text-black">
                creado el {{ fechasmall("DD [de] MMMM [de] YYYY", ticket.created_at) }} a las {{ fechasmall("hh:mm a",
                  ticket.created_at) }}
              </v-list-item-subtitle>
              <div>

              </div>

            </v-list-item>

            <v-list density="compact">
              <v-list-item :title="capitalizeWords(ticket.solicitante.nombres + ' ' + ticket.solicitante.apellidos)"
                :subtitle="capitalizeWords(ticket.solicitante.tipouser === 'colaborador' ? ticket.solicitante.cargo : ticket.solicitante.carrera)">
                <template v-slot:prepend="">
                  <v-avatar size="40" class="rounded-lg" start><v-img
                      :src="require('@/assets/' + ticket.solicitante.tipouser.toLowerCase() + '.png')"></v-img></v-avatar>
                </template>
              </v-list-item>
            </v-list>
            <v-card-text v-html="ticket.body" class="body-ticket caja-texto-styles"></v-card-text>
            <v-card-text v-if="ticket.fileadjunto">
              <div class="d-block text-subtitle-2 font-weight-bold pa-2">Archivos adjuntados</div>
              <div class="py-3">

                <v-chip v-if="!(ticket.fileadjunto instanceof Array)" label size="large"
                  @click="onOpenDialogAdjunto(file)"
                  class="font-weight-medium text-caption mr-2 pl-1 bg-blue-grey-darken-2">
                  <div start class="bg-white rounded-sm pa-1 d-flex align-center justify-center">
                    <v-img :src="require('@/assets/' + iconExtension(getFileNameFromURL(file)['extension']))"
                      class="mx-auto" :width="25" cover />
                  </div>
                  <div class="d-inline-block text-truncate px-2" style="max-width: 150px;">{{
                    getFileNameFromURL(ticket.fileadjunto) ? getFileNameFromURL(ticket.fileadjunto)['name'] : '' }}
                  </div>
                  <!--<a :href="ticket.fileadjunto" download>
                    <v-avatar end label class="bg-blue-grey-lighten-1 rounded-sm">
                      <v-icon size="15">mdi:mdi-arrow-down-bold</v-icon>
                    </v-avatar>
                  </a>-->
                </v-chip>

                <v-chip v-else v-for="file in ticket.fileadjunto" label size="large" @click="onOpenDialogAdjunto(file)"
                  class="font-weight-medium text-caption ma-1 ma-md-1 pl-1 bg-blue-grey-darken-2">
                  <div start class="bg-white rounded-sm pa-1 d-flex align-center justify-center">
                    <v-img :src="require('@/assets/' + iconExtension(getFileNameFromURL(file)['extension']))"
                      class="mx-auto" :width="25" cover />
                  </div>
                  <div class="d-inline-block text-truncate px-2" style="max-width: 150px;">{{
                    getFileNameFromURL(file) ? getFileNameFromURL(file)['name'] : '' }}</div>
                  <!--<a :href="file" download>
                    <v-avatar end label class="bg-blue-grey-lighten-1 rounded-sm">
                      <v-icon size="15">mdi:mdi-arrow-down-bold</v-icon>
                    </v-avatar>
                  </a>-->
                </v-chip>



              </div>
            </v-card-text>
            <CommentComponent :idticket="ticket.idticket" />
          </v-card>
        </div>
        <ComentariosComponent :idticket="ticket.idticket" />
      </v-col>

      <v-col cols="12" md="3" lg="2" xl="2" class="px-md-2  py-1 py-md-0" style="width: 20%;">
        <CrearTicketAnidadoComponent :ticket="ticket"></CrearTicketAnidadoComponent>
        <ConfirmResultTicketComponent class="mb-3" v-if="ticket.estado && ticket.estado.isCierre" :ticket="ticket" />
        <ActualizarTicketComponent :readonly="isRoleVisitor()" :class="disableClass(ticket.estado)">
        </ActualizarTicketComponent>
      </v-col>
      <v-col cols="12" md="3" lg="2" xl="2" class="px-md-2 py-1 py-md-0" style="width: 20%;">
        <InfoTicketComponent :ticket="ticket" />
        <!--<ChatInitView class="mt-3"/>-->
      </v-col>
    </v-row>
    <VisualizarAdjuntoComponent></VisualizarAdjuntoComponent>
  </v-container>
</template>

<script>

import InfoTicketComponent from "../components/VisualizarTicket/InfoTicketComponent.vue";
import CommentComponent from "../components/VisualizarTicket/CommentComponent.vue";
import NotasComponent from "../components/VisualizarTicket/NotasComponent.vue";
import ComentariosComponent from "../components/VisualizarTicket/ComentariosComponent.vue";
import ActualizarTicketComponent from "../components/VisualizarTicket/ActualizarTicketComponent.vue";
import ContadorAtencionComponent from "../components/VisualizarTicket/ContadorAtencionComponent.vue"
import ChatInitView from '@/modules/Chat/views/ChatInitView.vue'
import CambiarCategoriaTicketComponent from "../components/CambiarCategoriaTicketComponent.vue";
import ConfirmResultTicketComponent from "../components/VisualizarTicket/ConfirmResultTicketComponent.vue";
import CrearTicketAnidadoComponent from "../components/VisualizarTicket/CrearTicketAnidadoComponent.vue";


import { mapState, mapActions, mapMutations } from "vuex";
import Filters from "@/modules/Shared/Filters";
import { socket } from "@/ws-client";
import VisualizarAdjuntoComponent from "../components/VisualizarTicket/VisualizarAdjuntoComponent.vue";



export default {
  mixins: [Filters],
  components: {
    InfoTicketComponent,
    CommentComponent,
    NotasComponent,
    ComentariosComponent,
    ActualizarTicketComponent,
    ContadorAtencionComponent,
    ChatInitView,
    CambiarCategoriaTicketComponent,
    ConfirmResultTicketComponent,
    CrearTicketAnidadoComponent,
    VisualizarAdjuntoComponent
  },
  data() {
    return {
      comentar: false,
    };
  },
  watch: {
    listTickets:
    {
      handler(newval, oldval) {
        if (newval) {
          this.getTicket(this.$route.params.codigoticket)
        }
      }
    },
    ticket: {
      handler(newval) {
        if (this.ticket) {
          this.openTicket(this.ticket.idticket)
        }
      }
    }
  },
  computed: {
    ...mapState('tickets', ['ticket', 'listTickets', 'dialogFileAdjunto']),

    cierreBTN() {
      if (this.ticket) {
        if (this.ticket.estado == null) {
          return true
        }
        else if (this.ticket.estado.isCierre == 0) {
          return true
        }
        else {
          return false
        }
      }
    },


  },

  methods:
  {
    ...mapActions('tickets', ['getTicket', 'closedTicket', 'deleteTicket', 'openTicket']),
    ...mapMutations('tickets', ['resetTicket', 'switchDialogFileAdjunto', 'setUrlFile']),
    ...mapMutations('answers', ['switchDialogTicket', 'resetListAnswers', 'resetUrlFile']),

    obtenerTicketsRelacionados(ticket, arrayTickets = []) {
      // Verificar si el ticket tiene tickets relacionados
      if (ticket.tickets_relacionados && typeof ticket.tickets_relacionados === 'object') {

        arrayTickets.push(ticket.tickets_relacionados)

        this.obtenerTicketsRelacionados(ticket, arrayTickets)

      }
      // Devolver el array de tickets relacionados
      console.log(arrayTickets)
    },

    onOpenDialogAdjunto(file) {
      this.resetUrlFile()
      this.switchDialogFileAdjunto()
      this.setUrlFile(file)
    }

  },

  mounted() {
    this.resetUrlFile()
    this.resetListAnswers()
    this.resetTicket()

    socket.on('UPDATE_PREVIEW_TICKET', data => {
      this.resetTicket()
      this.getTicket(this.$route.params.codigoticket)
    })

    this.getTicket(this.$route.params.codigoticket)



    if (this.isRoleVisitor()) {
      this.$router.push({ name: 'visualizeTicket' })

    }



  }
};
</script>

<style>
.body-ticket img {
  max-width: 100% !important;
}
</style>