<template>

        <v-card elevation="0" class="my-0 px-2 px-md-5 rounded-0 bg-grey-lighten-4" >
            <v-card-title class="">
                <div class="text-subtitle-2">Respuestas</div>
            </v-card-title>
            
            <AnswersFromTicketComponent :idticket="idticket"/>
            
        </v-card>

</template>


<script>
import AnswersFromTicketComponent from "@/modules/Answers/components/AnswersFromTicketComponent.vue"
export default {
    components:{
        AnswersFromTicketComponent
    },

    props:{
        idticket:
        {
            type: Number
        }
    }
}
</script>