import tipo from "@/modules/Configuracion/store/tipo";
import registroticket from "./registroticket";
import TicketService from "../service/TicketService";
import utiles from "@/modules/Shared/utiles/utiles";
import Swal from "sweetalert2";
import router from "@/router";
import { socket } from "@/ws-client";
import store from "@/store";

const TicketSV = new TicketService();

export default {
  namespaced: true,

  state: {
    dialogEstado: false,
    estadosTickets: [],
    prioridadTickets: [],
    listTickets: [],
    tiposTicket: [],
    loadingChangeTipo: false,
    filters: {
      agentes: [],
      tipo: null,
      categoria: null,
      prioridad: null,
      dninombre: null,
      fecha: null,
      tiposolicitante: null,
      iduser: null,
      itemsPerPage: 25,
      page: 1
    },
    cambioCategories: {
      idticket: -1,
      categoria_ticket: "",
      subcategoria_id: "",
      tipo_ticket: ""
    },
    cambioCategoriesDefault: {
      idticket: -1,
      categoria_ticket: "",
      subcategoria_id: "",
      tipo_ticket: ""
    },
    loadingTickets: false,
    ticket: null,
    buscarticket: {
      dni: "",
      codeticket: ""
    },
    buscarticketDefault: {
      dni: "",
      codeticket: ""
    },
    loadingbuscar: false,
    notfind: false,
    totalItems: 0,
    loadingConfirmResult: false,
    loadingChangeCategoria: false,
    dialogCambiarCategoria: false,

    arrayTicketsRelacionados:[],

    dialogFileAdjunto: false,
    urlFile: ""
  },

  mutations: {
    switchDialogEstado(state) {
      state.dialogEstado = !state.dialogEstado
    },

    setEstadosTickets(state, payload) {
      state.estadosTickets = payload;
    },

    setPrioridadTickets(state, payload) {
      state.prioridadTickets = payload
    },

    setTickets(state, payload) {
      state.listTickets = payload
    },

    setTiposTicket(state, payload) {
      state.tiposTicket = payload
    },

    setTicket(state, payload) {
      state.ticket = null;
      state.ticket = Object.assign({}, payload);
    },

    resetBusquedaTicket(state) {
      state.buscarticket = Object.assign({}, state.buscarticketDefault);
    },

    resetTicket(state) {
      state.ticket = null
    },
    switchDialogCategories(state) {
      state.dialogCambiarCategoria = !state.dialogCambiarCategoria
    },

    setEditCambiarCategoriaTicket(state, ticket) {
      state.cambioCategories.idticket = ticket.idticket
      state.cambioCategories.categoria_ticket = parseInt(ticket.categoria_ticket)
      state.cambioCategories.subcategoria_id = parseInt(ticket.subcategoria_id)
      state.cambioCategories.tipo_ticket = parseInt(ticket.tipo_ticket)
    },

    resetChangeCateogiraTicket(state){
      state.cambioCategories = Object.assign({}, state.cambioCategoriesDefault)
    },

    switchDialogFileAdjunto(state)
    {
      state.dialogFileAdjunto = !state.dialogFileAdjunto
    },

    setUrlFile(state, file)
    {
      state.urlFile =file
    },

    resetUrlFile(state)
    {
      state.urlFile = ""
    }
    
  },
  actions: {
    async getTicketsAxios({ commit, state }, params = { loading: true, iduser: null, page, isAgente: false }) {
      const { loading, iduser, page, isAgente } = params
      state.loadingTickets = loading
      state.filters.iduser = iduser

      state.filters.page = page
      state.filters.isAgente = isAgente

      
      try {
        const data = await TicketSV.getAll(state.filters);
        console.log(data)
        commit('setTickets', data.data)
        state.totalItems = data.total
        state.loadingTickets = false
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
        state.loadingTickets = false
      }

    },

    async getTicket({ commit, state }, codigoticket) {
      try {
        const data = await TicketSV.showTicket(codigoticket);
        commit('setTicket', data)
        console.log(data)
      }
      catch (error) {
        console.log(error)
        router.push('/tickets')
      }
    },

    async findTicket({ commit, state }) {
      state.loadingbuscar = true;
      state.notfind = false;
      try {
        const data = await TicketSV.findTicket(state.buscarticket)
        commit('setTicket', data)
        state.loadingbuscar = false;
        state.notfind = false;
      }
      catch (error) {
        console.log(error)
        state.loadingbuscar = false;
        state.notfind = true;
      }
    },

    async deleteTicket({ commit, state }, idticket) {
      const dialog = await Swal.fire({
        title: "¿Desea eliminar este ticket?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ELIMINAR"
      })

      if (!dialog.isConfirmed) { return }

      try {
        const data = await TicketSV.deleteTicket(idticket);

        commit('utiles/alertConfirm', 'Se eliminó el ticket')

        socket.emit('addTicket', false)
        router.push({ name: 'tickets' })
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async openTicket({ commit, state }, idticket) {
      try {

        const data = await TicketSV.openTicket(idticket);
        socket.emit('addTicket', false)

      } catch (error) {
        console.log(error)
        commit('utiles/alertError')

      }
    },

    async changeTypeAxios({ commit, dispatch, state }, { idticket, ticket_tipo_new }) {
      try {
        const data = await TicketSV.changeType({ idticket: idticket, tipo_ticket: ticket_tipo_new });

        dispatch('getTicketsAxios', false)

        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async changePriorityAxios({ commit, dispatch, state }, { idticket, ticket_priority_new }) {
      try {
        const data = await TicketSV.changePriority({ idticket: idticket, prioridad_ticket: ticket_priority_new });

        dispatch('getTicketsAxios', false)

        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async changeStateAxios({ commit, dispatch, state }, { idticket, ticket_estado_new }) {
      try {
        const data = await TicketSV.changeState({ idticket: idticket, estado_ticket: ticket_estado_new });

        dispatch('getTicketsAxios', false)

        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)

      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async changeAgenteAxios({ commit, dispatch, state }, { idticket, agente_new }) {
      try {
        const data = await TicketSV.changeAgente({ idticket: idticket, agente: agente_new });

        dispatch('getTicketsAxios', false)

        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async closedTicket({ commit, dispatch, state }, idticket) {
      const dialog = await Swal.fire({
        title: "¿Desea cerrar este ticket?",
        text: "El ticket se marcará como resuelto",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "CONFIRMAR"
      })

      if (!dialog.isConfirmed) { return }
      try {
        const data = await TicketSV.closedTicket(idticket)
        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)
        commit('utiles/alertConfirm', 'Se cerró el ticket')
        router.push({ name: 'tickets' })
      }
      catch (error) {
        console.log(error)
        commit('utiles/alertError')
      }
    },

    async confirmarTicket({ commit, dispatch, state }, idticket) {
      const dialog = await Swal.fire({
        title: "Confirmar Resolución del Ticket",
        text: "¿Estás seguro de que el ticket se ha resuelto? Si es así, por favor, confirma.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "CONFIRMAR"
      })

      if (!dialog.isConfirmed) { return }

      state.loadingConfirmResult = true;
      try {

        const data = await TicketSV.confirmarCierre(idticket)
        console.log(data)
        socket.emit('addTicket', false)
        socket.emit('updatePreviewTicket', false)
        state.loadingConfirmResult = false
        commit('utiles/alertConfirm', 'Se confirmó exitosamente!')
        dispatch('getTicket', data.codigo_ticket)
      }
      catch (error) {
        console.log(error)
        state.loadingConfirmResult = false
      }
    },


    async changeCategories({ state, commit, dispatch }) {
      state.loadingChangeCategoria = true
      try {
        const data = await TicketSV.changeCategories(state.cambioCategories);
        commit('setTicket', data)

        commit('resetChangeCateogiraTicket')

        // Recargar las categorías y subcategorías
        await store.dispatch('configuracion/categoria/getCategoriasAxios', data.tipo_ticket)
        await store.dispatch('configuracion/categoria/getSubCategoriasAxios', data.categoria_ticket)

        commit('setEditCambiarCategoriaTicket', data)

        state.dialogCambiarCategoria = false
        state.loadingChangeCategoria = false
      } catch (error) {
        console.log(error)
        commit('utiles/alertError')
        state.loadingChangeCategoria = false
      }
    }


  },
  modules: {
    registroticket,
    utiles
  }
};