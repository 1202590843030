import utiles from "@/modules/Shared/utiles/utiles"
import AnswersService from "../services/AnswersService"
import store from "@/store"
import Swal from "sweetalert2"
import { socket } from "@/ws-client"


const AnswerService = new AnswersService()
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
export default
    {
        namespaced: true,
        modules: {
            utiles
        },
        state: {
            answers: [],
            answer: {
                file: [],
                message: "",
                ticket_id: "",
                user_id: "",
                notificar: "si",
                cc: []
            },

            answerDefault: {
                file: [],
                message: "",
                ticket_id: "",
                user_id: "",
                notificar: "si",
                cc: []
            },

            copiaCorreo:{
                email: ""
            },

            loadingAnswers: false,
            loadingAddAnswer: false,
            dialogCommentTicket: false
        },
        mutations: {
            setListAnswers(state, payload) {
                state.answers = payload
            },

            resetListAnswers(state)
            {
                state.answers = []
            }, 

            setAnswer(state, payload) {
                state.answer = Object.assign({}, payload)
            },

            resetAnswer(state) {
                state.answer = Object.assign({}, state.answerDefault)
                state.answer.file = []
                state.answer.cc = []
                state.copiaCorreo.email = ""
            },

            changeFileInput(state, event){
                state.answer.file.push(event.target.files[0])
            },

            deleteFileUpload(state, file)
            {
                const index = state.answer.file.indexOf(file)

                state.answer.file.splice(index, 1);
            },

            switchDialogTicket(state){
                state.dialogCommentTicket = !state.dialogCommentTicket
            },
       
            addEmailCC(state) {
                // Verificar si la última tecla presionada fue una coma
                const lastChar = state.copiaCorreo.email.slice(-1);
                if (lastChar === ',') {
                    // Eliminar la coma y agregar el correo al array ticket.cc
                    const trimmedEmail = state.copiaCorreo.email.slice(0, -1).trim();
                    if (trimmedEmail !== '' && isValidEmail(trimmedEmail)) {
                        state.answer.cc.push(trimmedEmail);
    
                        // Limpiar el campo de correo electrónico después de agregarlo al array
                        state.copiaCorreo.email = '';
                    }
                }
            },
    
            removeCC(state, index) {
                if (index !== -1) {
                    // Verificar que el índice es válido (-1 indica que el elemento no se encontró)
                    state.ticket.cc.splice(index, 1);
                  }
              },

        },
        actions: {
            async getAllAnswers({ commit, state }, params = { loading: true, idticket}) {
                const {loading, idticket} = params
                state.loadingAnswers = loading
                commit('resetListAnswers');
                try {
                    const data = await AnswerService.getList(idticket)
                    commit('setListAnswers', data)
                     state.loadingAnswers = false

                }
                catch (error) {
                    console.log(error)
                    state.loadingAnswers = false
                }
            }, 

            async setAnswer({commit, dispatch, state}, idticket)
            {
                state.answer.ticket_id = idticket;
                state.answer.user_id = store.state.user.iduser;
                state.loadingAddAnswer = true
                const formdata = new FormData();
                for (let key in state.answer) {

                    if (key === 'file') {
                        const files = state.answer[key];
                        if(!files){ continue}
                        for (let i = 0; i < files.length; i++) {
                            formdata.append('file[]', files[i]);
                        } 
                        continue;
                    }

                    formdata.append(key, state.answer[key]);
                  }
                try {
                    const data = await AnswerService.insert(formdata)
                    state.loadingAddAnswer = false
                    dispatch('getAllAnswers', {loading: true, idticket: idticket})
                    commit('resetAnswer')
                    socket.emit('addAnswer', true);
                    state.dialogCommentTicket = false
                }
                catch (error) {
                    console.log(error)
                    state.loadingAddAnswer = false
                }
            },

            async deleteAnswer({dispatch, state}, params = { loading: true, idticket, idanswer})
            {
                const {loading, idticket, idanswer} = params
                const confirmate = await Swal.fire({
                    title: "¿Deseas eliminar la respuesta?",
                    icon: "warning",
                    cancelButtonText: "CANCELAR",
                    showCancelButton: true,
                    confirmButtonText: "ELIMINAR"
                })
                
                if(!confirmate){return}
                try {
                    const data = await AnswerService.delete(idanswer)
                    socket.emit('addAnswer', true);
                    dispatch('getAllAnswers', {loading: loading, idticket: idticket})
                }
                catch (error) {
                    console.log(error)
                }
            }
        }
    }