<template>
    <v-container>
        <v-col cols="12" md="9" xl="8" class="mx-auto pa-0">
            <v-row>
                <v-col cols="12" md="4" xl="3" class="pa-1">
                    <v-card elevation="0" class="rounded-0 ma-1">
                        <v-card-text class="text-center ">
                            <v-avatar size="150" class="bg-blue-grey-lighten-5 text-blue-grey-lighten-1">
                                <v-icon size="60">fa:fa-solid fa-user</v-icon>
                            </v-avatar>
                            <div class="pt-4">
                                <div class="text-subtitle-1 font-weight-bold">{{ capitalizeWords(userLogin.nombres + ' ' +
                                    userLogin.apellidos) }}</div>
                                <div class="text-subtitle-2">{{ capitalizeWords(userLogin.cargo || userLogin.carrera) }}
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="8" xl="8" class="pa-1">

                    <v-card elevation="0" class="rounded-0 ma-1 pa-md-4 pa-xl-6">

                        <v-form v-model="validateForm">
                            <v-card-text style="overflow-y: hidden !important">
                                <v-row class="pa-0">
                                    <v-col cols="12">
                                        <div class="text-subtitle-1 font-weight-bold">Cambiar datos personales</div>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <label for="">TIPO DOCUMENTO</label>
                                        <v-select variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.tipo_documento" bg-color="grey-lighten-5"
                                            class="compact-full-input" :items="['DNI', 'Carnet de Extranjería']"
                                            :rules="[rules.required]"></v-select>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <label for="">NÚMERO DE DOCUMENTO</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.numero_documento" bg-color="grey-lighten-5"
                                            class="compact-full-input" @change="getDNIColaborador()"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <label for="">Nombres</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.nombres" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <label for="">Apellidos</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.apellidos" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <label for="">Email</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.email" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required, rules.email]"></v-text-field>
                                    </v-col>


                                    <v-col cols="12" md="6" v-if="user.tipouser == 'colaborador'">
                                        <label for="">Cargo</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.cargo" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" v-if="user.tipouser == 'colaborador'">
                                        <label for="">Área</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.area" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" v-if="user.tipouser == 'estudiante'">
                                        <label for="">Carrera</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.carrera" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" v-if="user.tipouser == 'estudiante'">
                                        <label for="">Admisión</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.admision" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <label for="">Celular</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.celular" bg-color="grey-lighten-5" class="compact-full-input"
                                            :rules="[rules.required]">
                                            <template v-slot:prepend-inner>
                                                <v-icon size="20">fa:fa-solid fa-mobile-screen-button</v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <label for="">Whatsapp</label>
                                        <v-text-field variant="outlined" density="compact" hide-details="auto"
                                            v-model="user.whatsapp" bg-color="grey-lighten-5" class="compact-full-input">
                                            <template v-slot:prepend-inner>
                                                <v-icon size="20">fa:fa-brands fa-whatsapp</v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <label for="">Contraseña</label>
                                        <v-text-field :type="hidepass ? 'password' : 'text'" variant="outlined"
                                            density="compact" hide-details="auto" v-model="user.password"
                                            bg-color="grey-lighten-5" class="compact-full-input">
                                            <template v-slot:append-inner="" :rules="[rules.required]">
                                                <v-icon size="x-small" @click="hidepass = !hidepass">{{ hidepass ?
                                                    'fa:fa-solid fa-eye-slash' : 'fa:fa-solid fa-eye' }}</v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-col cols="12">
                                    <v-btn v-if="user.iduser" :disabled="!validateForm" @click="updateUserWithReload(true)"
                                        class="btn-system" variant="flat" block elevation="0">ACTUALIZAR</v-btn>

                                </v-col>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapState, mapMutations, mapActions } from 'vuex';
export default
    {
        mixins: [Filters],
        data() {
            return {
                validateForm: true,
                hidepass: true
            }
        },
        computed:
        {
            ...mapState({ userLogin: 'user' }),
            ...mapState("usuarios", [
                "tiposUsuario",
                "dialogEdit",
                'user',
            ]),
            ...mapState('usuarios/roles', ['listrole', 'loading']),
        },

        methods: {
            ...mapMutations('usuarios', ['pushUser']),
            ...mapActions('usuarios', ['updateUserWithReload'])
        },

        mounted() {
            this.pushUser(this.userLogin)
        }
    }
</script>