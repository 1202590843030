import axios from "axios";


export default class ConfirmRegistrationService {
    constructor() {
        this.apiColaboradores = axios.create({
            baseURL: "https://ctm-colaboradores.cetemin.edu.pe/api/v1/colaborador/",
            headers:
            {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer 3|G7fWXbLjroLuJdQCaa7IP1DJewgnCHNLSb4tIs9a"
            }

        })

        this.apiEstudiantes = axios.create({
            baseURL: "https://sige-stage-mw.cetemin.edu.pe/fix/student/",
            headers: {
                'Content-Type': "application/json",
                Accept: "application/json",
                Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE"
            }
        })

        this.domainsAccepts = ['estudiante.cetemin.edu.pe', 'cetemin.edu.pe', 'ciemam.com', 'enter.edu.pe']


    }


    validateDomain(email) {
        const containDomain = this.domainsAccepts.some(domain => email.includes('@' + domain))
        return containDomain;
    }

    getDomainEmail(email) {
        const match = email.match(/@([^.]+)\./);

        if (match) {
            return match[1];
        }

        return null;
    }


    getDni(email) {
        const partes = email.split("@");
        if (partes.length === 2) {
            return partes[0];
        } else {
            return null; // Si el formato no coincide, puedes manejarlo de esta manera
        }
    }

    async findDataUser(email, password) {


        if (!this.validateDomain(email)) { throw new Error('not-found') }

        const domainEmail = this.getDomainEmail(email)

        if (domainEmail && domainEmail.includes('estudiante')) {
            let dni = this.getDni(email)

            const student = await this.getDataStudent(dni)
            if (student && student.result.cDni === password) {
                return this.parsingDataFull(student)
            }

            throw new Error('not-password-register-student',)
        }



        if (domainEmail && !domainEmail.includes('estudiante')) {

            const collaborator = await this.getDataColaborador(email)
            if (collaborator && collaborator.documento.toString() === password) {
                return this.parsingDataFull(collaborator)
            }

            throw new Error('not-password-register-collaborator')
        }
    }




    async getDataStudent(dni) {
        const response = await this.apiEstudiantes.get('/search/' + dni);
        return response.data;
    }

    async getDataColaborador(email) {
        const response = await this.apiColaboradores.post('/correo', { correo: email });
        return response.data;
    }

    parsingDataFull(data) {
        let response = { numero_documento: "", apellidos: "", nombres: "", cargo: "", area: "", carrera: "", admision: "", celular: "", email: "", tipouser: "", password:"" };
        response = Object.assign({}, data)
        const namefull = this.separarNameFull(data.nombrecompleto || data.result.nomalu) 
        response.apellidos = namefull.apellidos
        response.nombres = namefull.nombres
        response.numero_documento = data.documento || (data.result && data.result.cDni) || "";
        response.carrera = data.result ? (data.result.specialtyName || '') : '';
        response.tipouser = data.result ? 'estudiante' : 'colaborador';
        response.email = (data.correo || (data.result && (data.result.cDni + '@estudiante.cetemin.edu.pe')) || '').toLowerCase();
        response.celular = data.telefono || '';
        response.admision =  data.result ? (data.result.period || '') : '';
        response.password = data.documento || (data.result && data.result.cDni) || "";
        return response;
    }


    separarNameFull(namefull)
    {
        let apellidos, nombres; 
        let separar = namefull.split(" ");
            //si el nombrecompleto tiene 4 elementos: son dos apellidos y dos nombres
            if(separar.length == 4){
                apellidos = separar[0] + ' ' + separar[1]
                nombres = separar[2] + ' ' + separar[3]
            }
            //si el nombrecompleto tiene 3 elementos: son dos apellidos y un nombre
            if(separar.length == 3){
                apellidos = separar[0] + ' ' + separar[1]
                nombres = separar[2]
            }
            //si el nombrecompleto tiene mas de 4 elementos: son dos apellidos y mas de un nombre
            // solo se usara el primer nombre
            if(separar.length > 4){
                apellidos = separar[0] + ' ' + separar[1]
                nombres = separar[2]
            }
        
            return { apellidos, nombres }
    }
}