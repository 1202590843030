<template>
    <div>
        <div class="pa-5 text-center" v-if="loadingAnswers">
            <v-col cols="12" class="mx-auto py-2"><v-progress-linear color="blue-lighten-3" indeterminate
                    :height="7"></v-progress-linear></v-col>
        </div>


        <div class="pa-5 text-center" v-if="answers.length == 0 && !loadingAnswers">
            <span> No hay respuestas </span>
        </div>

        <div v-for="answ in answers">
            <div class="d-flex justify-end" v-if="!isRoleVisitor() && isMyID(answ.user_id)">
                <v-avatar @click="deleteAnswer({ loading: true, idticket: answ.ticket_id, idanswer: answ.idanswer })"
                    size="x-small" elevation="0" class="bgLight2Theme rounded-sm" style="cursor: pointer;"><v-icon
                        size="14">mdi:mdi-delete</v-icon></v-avatar>
            </div>
            <v-card class="mb-3" elevation="0" style="background-color: transparent !important;">

                <v-card-actions style="min-height: 20px !important;"
                    class="text-blue-grey d-flex flex-column flex-md-row justify-space-between pt-3">
                    <div>
                        <v-avatar size="30" class="bg-blue-grey mr-2">
                            <v-img v-if="!answ.user.avatar" :src="require('@/assets/' +  answ.user.tipouser + '.png')"></v-img>
                            <v-icon  v-else size="20">mdi:mdi-account-tie</v-icon>
                        </v-avatar>
                        <span class="text-caption mr-2">
                            {{ capitalizeWords(oneName(answ.user.nombres) + ' ' + oneName(answ.user.apellidos)) }}
                        </span>

                    </div>

                    <span class="text-caption font-weight-medium">{{ fechasmall("DD MMM YYYY", answ.created_at) }} a las {{ fechasmall("hh:mm a",
                            answ.created_at) }}</span>

                </v-card-actions>

                <v-card-text class="pa-4">
                    <div v-html="answ.message" class="body-comment-ticket caja-texto-styles " style="overflow: auto; max-height: 1800px;"></div>
                    <div class="py-3">
                        <v-chip label v-for="f in answ.file" size="large" @click="onOpenDialogAdjunto(f)"
                            class="font-weight-medium text-caption mr-2 pl-1 bg-blue-grey-darken-2">
                            <div start class="bg-white rounded-sm pa-1 d-flex align-center justify-center">
                                <v-img :src="require('@/assets/' + iconExtension(getFileNameFromURL(f)['extension']))" class="mx-auto" :width="25"  cover/>
                            </div>
                            <div class="d-inline-block text-truncate px-2" style="max-width: 150px;">{{ getFileNameFromURL(f) ? getFileNameFromURL(f)['name'] : '' }}</div>
                           <!-- <a :href="f" target="_blank" download>
                                <v-avatar end label class="bg-blue-grey-lighten-1 rounded-sm">
                                    <v-icon size="15">mdi:mdi-arrow-down-bold</v-icon>
                                </v-avatar>
                            </a>-->
                        </v-chip>
                    </div>
                </v-card-text>


            </v-card>
            <v-divider></v-divider>
        </div>
        <VisualizarAdjuntoComponent></VisualizarAdjuntoComponent>
    </div>
</template>


<script>
import Filters from '@/modules/Shared/Filters'
import VisualizarAdjuntoComponent from '@/modules/Tickets/components/VisualizarTicket/VisualizarAdjuntoComponent.vue';
import { socket } from '@/ws-client';
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    mixins: [Filters],
    components:{VisualizarAdjuntoComponent},
    props:
    {
        idticket: {
            type: Number
        }
    },
    data() {
        return {
 
        }
    },

    computed: {
        ...mapState('answers', ['answers', 'loadingAnswers'])
    },

    methods: {
        ...mapActions('answers', ['getAllAnswers', 'deleteAnswer']),
        ...mapMutations('answers', ['resetListAnswers']),
        ...mapMutations('tickets', ['switchDialogFileAdjunto', 'setUrlFile', 'resetUrlFile']),
        onOpenDialogAdjunto(file)
        {
            this.resetUrlFile()
            this.switchDialogFileAdjunto()
            this.setUrlFile(file)
        }

    }, 
    created()
    {
        this.resetListAnswers()
    },
    mounted() {
        this.resetUrlFile()
        this.resetListAnswers()
        this.getAllAnswers({ loading: true, idticket: this.idticket }); 
 
        socket.on('LOAD_ANSWER', data =>{
            this.resetListAnswers()
            this.getAllAnswers({ loading: data, idticket: this.idticket });
            })
    },
}
</script>

<style>
.body-comment-ticket img{
  max-width: 100% !important;
}
</style>