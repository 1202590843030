<template>
    <v-row class="bg-pattern d-flex justify-center align-center " style="height: 100vh;">
        <v-col cols="10" md="10" xl="7" class="mx-auto mx-lg-none bg-white">
            <v-stepper v-model="step" show-actions elevation="0" alt-labels>
                <template v-slot:default="{ prev, next }">
                <v-stepper-header class="pa-0">
                    <v-stepper-item class="pa-1" :value="1">
                        <template v-slot:title v-if="$vuetify.display.mdAndUp">
                            <small>Bienvenida</small>
                        </template>
                    </v-stepper-item>
                    <v-divider></v-divider>
                    <v-stepper-item class="pa-1" :value="2">
                        <template v-slot:title v-if="$vuetify.display.mdAndUp">
                            <small>Confirma tus datos personales</small>
                        </template> 
                    </v-stepper-item>
                    <v-divider></v-divider>
                    <v-stepper-item class="pa-1" :value="3">
                        <template v-slot:title v-if="$vuetify.display.mdAndUp">
                            <small>Iniciar Sesión</small>
                        </template>
                    </v-stepper-item>
                </v-stepper-header>

                <v-stepper-window >
                    <v-stepper-window-item :value="1" :key="1">
                        <div class="pa-2 pa-md-5">
                            <div class="text-h6 font-weight-bold mb-2">¡Bienvenido al Sistema de Tickets!</div>
                            <span>¡Hola y bienvenido! Este es el primer paso para acceder a nuestro Sistema de Tickets. Por
                                favor, completa el formulario para comenzar a gestionar tus solicitudes de manera eficiente.
                                Si necesitas ayuda, estamos aquí para asistirte. Gracias por unirte a nosotros. ¡Esperamos
                                que tu experiencia sea excelente!
                            </span>
                        </div>
                    </v-stepper-window-item>

                    <v-stepper-window-item :value="2" :key="2">
                        <form-register-user-component @validateFormEvent="handleEventRegister"></form-register-user-component>
                    </v-stepper-window-item>

                    <v-stepper-window-item :value="3" :key="3">
                        <form-new-password-component></form-new-password-component>
                    </v-stepper-window-item>
                </v-stepper-window>
                

                <div class="d-flex justify-space-between">
                    <v-btn elevation="0" :disabled="step == 1" @click="prev">ANTERIOR</v-btn>
                    <v-btn elevation="0" :loading="loadingregisteruser"  :disabled="step == 3" @click="next" v-if="step != 2">SIGUIENTE</v-btn>
                    <v-btn elevation="0" :loading="loadingregisteruser" :disabled="!validateFormRegister" @click="saveAndContinue()" v-if="step == 2" >GUARDAR Y CONTINUAR</v-btn>
                </div>

                </template>
            </v-stepper>
        </v-col>
    </v-row>
</template>

<script>
import FormRegisterUserComponent from '../components/FormRegisterUserComponent.vue';
import FormNewPasswordComponent from '../components/FormNewPasswordComponent.vue'
import { mapState, mapActions } from 'vuex';
export default {
    components: {
        FormRegisterUserComponent,
        FormNewPasswordComponent
    },
    data() {
        return {
            items: [
                'Bienvenida',
                'Confirmar datos personales'
            ],
            step: 1,
            validateFormRegister: true
        }
    },

    computed:
    {
        ...mapState('usuarios',['loadingregisteruser'])
    },

    methods:{
        ...mapActions('login', ['getDataRegister']),
        ...mapActions('usuarios', ['createUserInvitado']),
        handleEventRegister(validate)
        {
            this.validateFormRegister = validate
        },
        async saveAndContinue()
        {
            try
            {
                await this.createUserInvitado()
                this.step = 3
            }
            catch(error)
            {
                console.log(error)
            }
        }
    },

    mounted()
    {
        this.getDataRegister({email: this.$route.params.email, password: this.$route.params.dni})
    }
}
</script>


<style>
.v-stepper-window{
    margin: 2px;
}
</style>