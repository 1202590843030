<template>
  <v-row  class="bg-pattern">
    <v-col
      style="height:  101.5vh"
      cols="10"
      md="4"

      class="d-flex justify-center align-center mx-auto mx-lg-none"
      :class="$vuetify.display.mdAndUp ? 'bg-white' : ''"
    >
      <component v-bind:is="currentComponent"></component>
    </v-col>
    <v-col cols="12" md="8" class="bg-pattern d-none d-lg-flex align-center justify-center" >
      <div style="width: 100%;" class="mb-10">
        <v-img :src="require('@/assets/support.svg')" class="mx-auto" width="65%"></v-img>
        <div class="bg-white rounded pa-5 mx-auto text-center" style="width: 65%;">Centro de Ayuda Tecnológica - TIC CETEMIN</div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import FormLoginComponent from "../components/FormLoginComponent.vue";
import FormResetPassComponent from "../components/FormResetPassComponent.vue";
export default {
  components: {
    FormLoginComponent,
    FormResetPassComponent
  },

  computed: {
    ...mapState("login", ["currentComponent"]),

  },
  methods:{
    
  }
};
</script>
