<template>
  <v-card elevation="0">
    <v-card-text>
      <scroller :widthScroll="'2000px'"></scroller>
      <v-data-table-server class="table-reference" :headers="visitor ? headersVisitor : headers" :items="tickets"
        fixed-header footer-hidden loading-text="" :height="$vuetify.display.mdAndDown ? '500px' : '89vh'"
        v-model:items-per-page="filters.itemsPerPage" :items-length="totalItems" :loading="loadingTickets"
        @update:options="loadTickets">

        <template v-slot:item="{ item }">
          <tr>
            <td :class="disableClass(item.estado)" >
              <v-menu open-on-hover :close-on-content-click="false" location="top">
                <template v-slot:activator="{ props }">
                  <div class="d-flex align-center " style="cursor: pointer;">
                    <div class="rounded-circle mr-2 d-flex align-center justify-center pa-1" style="width: 40px; height: 40px;" 
                    >
                      <v-img style="width: 40px;"
                        :src="require('@/assets/' + item.solicitante.tipouser.toLowerCase() + '.png')"></v-img></div>

                    <div v-bind="props">{{ capitalizeWords(oneName(item.solicitante.nombres) + ' ' +
                      oneName(item.solicitante.apellidos)) }}</div>
                  </div>
                </template>
                <v-card width="300">
                  <v-list>
                    <v-list-item :title="capitalizeWords(oneName(item.solicitante.nombres) + ' ' + oneName(item.solicitante.apellidos))"
                      :subtitle="item.solicitante.tipouser === 'colaborador' ? item.solicitante.cargo : item.solicitante.carrera">
                      <template v-slot:append="">
                        <v-avatar size="60" class="rounded-lg" start><v-img
                            :src="require('@/assets/' + item.solicitante.tipouser.toLowerCase() + '.png')"></v-img></v-avatar>
                      </template>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-text class="bgLight2Theme">
                    <div class="text-caption py-1"><v-icon start>mdi:mdi-email-outline</v-icon>{{ item.correo
                    }}
                    </div>
                    <div class="text-caption py-1"><v-icon start>mdi:mdi-phone-in-talk</v-icon>{{
                      item.celular }}
                    </div>
                    <div class="text-caption py-1"><v-icon start>mdi:mdi-whatsapp</v-icon>{{ item.whatsapp }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </td>
            <td :class="disableClass(item.estado)" >
              <div style="font-size: 12px;">{{ fechasmall("DD/MM/YY", item.created_at) }} </div>
            </td>
            <td :class="disableClass(item.estado)" >
              <div style="font-size: 12px;">{{ item.sede }}</div>
            </td>
            <td :class="disableClass(item.estado)" >
              <TipoTicketComponent :key="item.tipo" :width="'180px'" :tipo="item.tipo" :idticket="item.idticket"
                :disabled="isDisabled(item.estado)" :readonly="visitor"></TipoTicketComponent>
            </td>
            <td :class="disableClass(item.estado)" >
              <div @click="openTicket(item)" class="d-flex"
                style="width: 400px !important; cursor: pointer;">

                <div class="text-wrap"> {{ item.asunto }}</div>
              </div>
            </td>
            <td :class="disableClass(item.estado)" >
              <AgenteTicketComponent :width="'170px'" :key="item.agente" :idticket="item.idticket" :agente="item.agente"
                :disabled="isDisabled(item.estado)" :readonly="visitor"></AgenteTicketComponent>
            </td>
            <td :class="disableClass(item.estado)">
              <div>{{ item.ticket_referencia }}</div>
            </td>
            <td :class="disableClass(item.estado)" >
              <v-menu open-on-hover :close-on-content-click="false" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" style="cursor: pointer; height: auto !important;" size="small" label color="primary"
                    class="py-1 font-weight-medium" >
                      <div style="text-wrap: wrap; ">{{ item.categoria.nombre }}</div>
                  </v-chip>
                </template>
                <v-card width="300">
                  <v-list>
                    <v-list-item >
                      <div style="text-wrap: wrap;">{{ item.categoria.nombre }}</div>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-text class="bgLight2Theme text-wrap">
                    <div class="text-subtitle-2">
                      {{ item.categoria.descripcion }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </td>

            <td :class="disableClass(item.estado)" >
              <v-menu v-if="item.subcategoria" open-on-hover :close-on-content-click="false" location="top">
                <template v-slot:activator="{ props }">
                  <v-chip v-bind="props" style="cursor: pointer; height: auto !important; " size="small" label color="primary"
                    class="py-1 font-weight-medium">
                    <div style="text-wrap: wrap;">{{ item.subcategoria.nombre }}</div>
                  </v-chip>
                </template>
                <v-card width="300">
                  <v-list>
                    <v-list-item>
                        <div style="text-wrap: wrap;">{{ item.subcategoria.nombre }}</div>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-text class="bgLight2Theme text-wrap">
                    <div class="text-subtitle-2">
                      {{ item.subcategoria.descripcion }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </td>
            <td :class="disableClass(item.estado)" >
              <PrioridadTicketComponent :width="'190px'" :key="item.prioridad" :prioridad="item.prioridad" :idticket="item.idticket"
                :disabled="isDisabled(item.estado)" :readonly="visitor"></PrioridadTicketComponent>
            </td>

            <td :class="disableClass(item.estado)" >
              <EstadoTicketComponent :disabled="item.cierre_confirmado == 1" :width="'180px'" :key="item.estado" :estado="item.estado" :idticket="item.idticket"
                :readonly="visitor"></EstadoTicketComponent>
            </td>
            <td v-if="!visitor">
              <v-chip v-if="item.cierre_confirmado" class="py-1 no-cancel text-white" color="green-accent-3" label variant="flat">
                <v-icon size="small" start >fa:fa-solid fa-check-double</v-icon>
                <small>Solución
                  Confirmada</small></v-chip>
            </td>
          </tr>
        </template>

      </v-data-table-server>
    </v-card-text>
  </v-card>

  <v-overlay v-model="overlayConfirmTicket" class="align-center justify-center">
    <div class="stamp">
      <v-card width="600" class="stamp">
      <v-card-text class="text-center">
        <v-row dense>
          <v-col cols="4">
            <v-img :src="require('@/assets/graciasCTM.webp')" :width="200" class="mx-auto my-4" />
          </v-col>
          <v-col cols="8">
            <div class="text-h5 font-weight-bold pa-4 text-grey-darken-4">¡Tu ticket se ha registrado con éxito!</div>
            <div class="mb-2 pa-3">Hemos enviado un mensaje de confirmación a tu correo electrónico para que estés al tanto de
              los detalles.</div>
            <v-btn @click="closeOverlayConfirmTicket()" class="btn-system my-2" block elevation="0">ACEPTAR</v-btn>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>
    </div>
  </v-overlay>
</template>

<script>
import Filters from '@/modules/Shared/Filters'

import EstadoTicketComponent from "../components/EstadoTIcketComponent.vue";
import AgenteTicketComponent from './AgenteTicketComponent.vue';
import PrioridadTicketComponent from "./PrioridadTicketComponent.vue";
import TipoTicketComponent from "./TipoTicketComponent.vue";
import scroller from "@/components/scroller.vue"
import { mapActions, mapState, mapMutations } from 'vuex';
import { socket } from '@/ws-client';

export default {
  mixins: [Filters],
  components: {
    EstadoTicketComponent,
    AgenteTicketComponent,
    PrioridadTicketComponent,
    TipoTicketComponent,
    scroller
  },
  props: {
    tickets: {
      type: Object
    },

    visitor: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      headers: [
        {
          title: "Contacto",
          key: "solicitante",
          width: "200px",
          sortable: false,
        },
        {
          title: "Fecha",
          key: "fecha",
          width: "100px",
          sortable: false,
        },
        {
          title: "Sede",
          key: "sede",
          width: "80px",
          sortable: false,
        },
        {
          title: "Tipo",
          key: "tipo",
          width: "200px",
          sortable: false,
        },
        {
          title: "Ticket",
          key: "asunto",
          sortable: false,
          width: "450px",
        },
        {
          title: "Agente",
          key: "agente",
          width: "190px",
          sortable: false,
        },
        {
          title: "Ticket referencia",
          key: "ticket_referencia",
          sortable: false,
          width: "150px",
        },
        {
          title: "Categoria",
          key: "categoria",
          width: "300px",
          sortable: false,
        },
        {
          title: "Subcategoria",
          key: "subcategoria",
          width: "300px",
          sortable: false,
        },
        {
          title: "Prioridad",
          key: "prioridad",
          width: "190px",
          sortable: false,
        },
        
        {
          title: "Estado",
          key: "estado",
          width: "190px",
          sortable: false,
        },
        {
          title: "Confirmación",
          key: "cierre_confirmado",
          width: "190px",
          sortable: false,
        },
      ],
      headersVisitor: [
        {
          title: "Yo",
          key: "solicitante",
          width: "200px",
          sortable: false,
        },
        {
          title: "Fecha",
          key: "fecha",
          width: "100px",
          sortable: false,
        },
        {
          title: "Sede",
          key: "sede",
          width: "100px",
          sortable: false,
        },
        {
          title: "Tipo",
          key: "tipo",
          width: "200px",
          sortable: false,
        },
        {
          title: "Ticket",
          key: "asunto",
          width: "400px",
          sortable: false,
        },
        {
          title: "Agente",
          key: "agente",
          width: "190px",
          sortable: false,
        },
        {
          title: "Ticket referencia",
          key: "ticket_referencia",
          sortable: false,
          width: "150px",
        },
        {
          title: "Categoria",
          key: "categoria",
          width: "300px",
          sortable: false,
        },
        {
          title: "Subcategoria",
          key: "subcategoria",
          width: "300px",
          sortable: false,
        },
        {
          title: "Prioridad",
          key: "prioridad",
          width: "190px",
          sortable: false,
        },
        
        {
          title: "Estado",
          key: "estado",
          width: "190px",
          sortable: false,
        },
      ]
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState("tickets", ["loadingTickets", "totalItems", "filters"]),
    ...mapState("tickets/registroticket", ['overlayConfirmTicket'])
  },
  methods: {
    ...mapActions("tickets", ['getTicketsAxios']),
    ...mapMutations('tickets', ['setTicket']),
    ...mapMutations('tickets/registroticket', ['setTicket', 'closeOverlayConfirmTicket']),

    openTicket(ticket) {
      //this.openTicket(ticket);
      if (!this.isRoleVisitor()) {
        this.$router.push({ name: 'ticketView', params: { codigoticket: ticket.codigo_ticket } })
      } else {
        this.$router.push({ name: 'visualizeTicket', params: { codigoticket: ticket.codigo_ticket } })
      }
    }, 

    loadTickets({ page, itemsPerPage, sortBy }) {
      let iduser = this.isRoleVisitor() ? this.user.iduser : null
      this.getTicketsAxios({ loading: true, iduser, page: page })
    }
  },


  mounted() {
    let iduser = this.isRoleVisitor() ? this.user.iduser : null

    this.getTicketsAxios({ loading: true, iduser, page: 1, isAgente: this.isAgente() });

    socket.on('LOAD_TICKET', data => {
      this.getTicketsAxios({ loading: false, iduser, page: 1 });
    })
  }



};
</script>

<style lang="scss" >
.close-ticket {
  opacity: 0.60;
  filter: grayscale(100%);
}

.cancel-ticket {
  text-decoration: line-through;
  filter: grayscale(100%);
}

.no-cancel {
  opacity: 1 !important;
  filter: grayscale(0) !important;
  -webkit-filter: grayscale(0) !important;
}
</style>
