<template>
  <v-card elevation="0" class="px-3">
    <div class="mb-8 text-left">
      <h3>Recuperar Contraseña</h3>
      <span
        >Ingresa tu email para porder recibir los pasos para cambiar tu
        contraseña</span
      >
    </div>
    <v-form v-model="formvalidate">
    <v-text-field
      type="email"
      label="Email"
      density="compact"
      variant="outlined"
      hide-details="auto"
      class="mb-3"
      v-model="restorepass.email"
      :rules="[rules.email, rules.required]"
    ></v-text-field>

    <v-btn
      variant="flat"
      size="small"
      class="mb-2"
      @click="switchComponent('FormLoginComponent')"
    >
      Volver a Login</v-btn
    >

    <v-btn :disabled="!formvalidate" @click="getsendemailrestorepass()" :loading="loadingsendmail" color="indigo 900" block class="font-weight-medium">
      ENVIAR ENLACE</v-btn
    >
  </v-form>
  </v-card>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  mixins:[Filters],
  data()
  {
    return{
      formvalidate: true
    }
  },
    computed:{
      ...mapState('login', ['restorepass', 'loadingsendmail'])
    },
    methods:{
        ...mapMutations('login',['switchComponent']),
        ...mapActions('login', ['getsendemailrestorepass'])
    }
}
</script>
