import axios from "axios";

export default class ApiEstudianteService{
    constructor(){
        this.axios = axios.create({
            baseURL: "https://sige-stage-mw.cetemin.edu.pe/fix/student/",
            headers: {
                'Content-Type': "application/json",
                Accept: "application/json",
                Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjZXRlbWluIiwiYXVkIjoid2ViIiwiaWF0IjoxNjYyMDQ0MTM1LCJleHAiOjE2NjMzNDAxMzUsImFrIjoiNWNlNTc2ZDliN2MzZjMxNjYifQ.468NjhnVKfbd_tlgfdiJbLrwXC6jcZo42QP-tAJHgBE"
            }
        })
    }


    async get(numero){
        const response = await this.axios.get('/search/' + numero)
        return response.data
    }
}