import utiles from "@/modules/Shared/utiles/utiles";
import store from "@/store";
import axios from "axios";
export default class TicketService{
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api, 
            headers: {
                'Content-Type': "multipart/form-data",
                Accept: "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        })

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll($params) {
        const response = await this.axios.get('ticket?page=' + $params.page, { params: $params});
        return response.data;
    }

    async findTicket(buscarticket)
    {
        const response = await this.axios.get('findticket', { params: buscarticket});
        return response.data;
    }

    async showTicket($codigoticket)
    {
        const response = await this.axios.get('show-ticket?codigo_ticket=' + $codigoticket);
        return response.data;
    }


    async createTicket(data) {
        const response = await this.axios.post('ticket', data);
        console.log(response)
        return response.data;
    }


    async updateTicket(data) {
        const response = await this.axios.put('ticket', data);
        return response.data;
    }

    async deleteTicket(id) {
        const response = await this.axios.delete('ticket?idticket=' + id );  
        return response.data;
    }
    
    async changeCategories(data){
        const response = await this.axios.post('ticket-change-categories', data);
        return response.data;
    }

    async changeType(data){
        const response = await this.axios.post('ticket-change-type', data);
        return response.data;
    }

    async changePriority(data){
        const response = await this.axios.post('ticket-change-priority', data);
        return response.data;
    }

    async changeState(data){
        const response = await this.axios.post('ticket-change-estado', data);
        return response.data;
    }

    async changeAgente(data){
        const response = await this.axios.post('ticket-change-agente', data);
        return response.data;
    }

    async closedTicket(idticket)
    {
        const response = await this.axios.post('ticket-closed', {idticket: idticket})
        return response.data;
    }

    async deleteTicket(idticket)
    {
        const response = await this.axios.delete('ticket?idticket=' + idticket)
        return response.data;
    }

    async openTicket(idticket)
    {
        const response = await this.axios.post('ticket-open', {idticket:idticket})
        return response.data
    }

    async confirmarCierre(idticket)
    {
        const response = await this.axios.post('ticket-confirmate', {idticket:idticket})
        return response.data
    }
}