import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
//Icons
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'




const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
      mdi,
    }
  },
})

//Importar modulos

import LoginModule from './modules/Login'
import HomeModule from './modules/Home'
import TicketsModule from './modules/Tickets'
import UsuariosModule from './modules/Usuarios'
import AgentesModule from './modules/Agentes'
import Configuracion from './modules/Configuracion'
import AnswersModule from './modules/Answers';
import ChatModule from './modules/Chat';
import DashboardModule from './modules/Dashboard';
//End Importar modulos


import { registerModules } from "./register-modules";

registerModules({
  //ingresar modulos
  home:HomeModule,
  login: LoginModule,
  tickets: TicketsModule,
  usuarios: UsuariosModule,
  agentes: AgentesModule,
  configuracion: Configuracion,
  answers: AnswersModule,
  chat:ChatModule,
  dashboard: DashboardModule
})



import abillitymixin from './services/abilitymixin'
import './registerServiceWorker'


createApp(App).use(store).use(router).use(vuetify).use(abilitiesPlugin, ability).mixin(abillitymixin).mount('#app')
