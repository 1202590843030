<template>
  <v-app-bar :elevation="0" density="compact" class="" id="navbarcentral">
    <v-container fluid class="d-flex align-center">
      <div class="d-flex align-center">
        <v-img :src="require('@/assets/logo.svg')" width="120px" cover></v-img>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img :src="require('@/assets/logo-tic.svg')" width="70" class="mr-4" cover></v-img>
      </div>
      <v-menu v-model="menu" :close-on-content-click="false" location="end" transition="scale-transition">
        <template v-slot:activator="{ props }">
          <v-avatar style="cursor: pointer;" v-bind="props" class="bgBlackTheme text-white" density="comfortable">
            <h4>{{ initialName(user.nombres + ' ' + user.apellidos) }}</h4>
          </v-avatar>
        </template>
        <v-card width="300">
          <v-card-text class="bgBlue2Theme text-white d-flex align-center">
            <v-avatar class="bg-white rounded-lg mr-3">
              <v-icon style="color: #48589e !important;">fa:fa-solid fa-user-tie</v-icon>
            </v-avatar>
            <div>
              <span class="d-block text-subtitle-1" style="line-height: 1;">{{ capitalizeWords(oneName(user.nombres) + ' '
                + oneName(user.apellidos)) }}</span>
              <small>{{ user.cargo }}</small>
            </div>
          </v-card-text>
          <div class="py-2">
            <v-list-item density="compact" @click="logout()">
              <template v-slot:prepend>
                <v-icon icon="fa:fa-solid fa-right-from-bracket"></v-icon>
              </template>
              <v-list-item-title>Cerrar sesión</v-list-item-title>
            </v-list-item>
          </div>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import Filters from '@/modules/Shared/Filters';
import { mapState, mapActions } from 'vuex';
export default {
  mixins: [
    Filters
  ],
  data() {
    return {
      menu: false
    }
  },

  computed:
  {
    ...mapState(['user'])
  },

  methods:{
    ...mapActions('login', ['logout'])
  }
}
</script>
 